import React from "react"
import { Link } from "gatsby"
import PlaceholderImg from "../images/default-thumbnail.jpg" 
import { resolveImageUrl } from "../utils/resolveImageUrl"

const ProjectThumbnail = ({ node }) => {
  const title = node.frontmatter.title || node.fields.slug;
  const thumbnail = resolveImageUrl(node.frontmatter.thumbnail || PlaceholderImg);
  const previewText = node.frontmatter.previewText || node.excerpt;

  return (
    <div key={node.fields.slug} className="relative isolate flex flex-col justify-end overflow-hidden bg-gray-900 md:pt-44 lg:pt-40">
      <Link to={node.fields.slug} className="group p-5">
        <img
          src={thumbnail}
          alt={`${title} thumbnail`}
          className="absolute inset-0 -z-10 h-full w-full object-cover transition-transform duration-500 group-hover:scale-110"
        />
        <div className="flex flex-wrap items-center gap-y-1 overflow-hidden text-sm pt-20 md:pt-0 leading-6 text-gray-300 transition-opacity duration-300 opacity-0 group-hover:opacity-100">
          <h2 className="text-base md:font-semibold md:text-lg">{title}</h2>
          <p className="leading-4 hidden md:inline-block" dangerouslySetInnerHTML={{ __html: previewText }} />
          <div className="absolute inset-0 -z-10 bg-gradient-to-t from-black via-black/40" />
          <div className="absolute inset-0 -z-10 ring-black/10" />
        </div>
      </Link>
    </div>
  )
}

export default ProjectThumbnail
